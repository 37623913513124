@import "@/src/styles/common.scss";
.add-container {
    display: grid;
    grid-template-columns: 25% auto;
    grid-row-gap: 0.2rem;
    margin-bottom: 0.3rem;
    .picker-wrapper {
        width: 100%;
        align-items: flex-start;
        .outline-div {
            width: calc(100% / 3);
        }
        .icon {
            right: 0.1rem;
        }
    }
    .lens-add {
        @include use-font-small($text_2);
        grid-column: 2/ -1;
    }
}
.pd-iframe-container {
    .pd-iframe {
        width: 100%;
    }
    .rx-form-hlep-p {
        font-size: 0.28rem;
        color: $text_2;
    }

    .rx-form-help-title {
        margin-bottom: 0.3rem;
        text-align: left;
        @include use-font-title($text_2);
    }
}
