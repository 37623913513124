@import "@/src/styles/common.scss";
.carouselContainer {
    position: relative;
    overflow: hidden;
    height: calc(var(--vh, 100vh) * 100 - 1rem); /* 根据需要调整 */
    width: 100%;
    will-change: transform;
}

.carouselItem {
    height: calc(var(--vh, 100vh) * 100 - 1rem);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(247, 249, 250); /* 根据需要调整 */
}

// 横向的滚动
.horizontalItem {
    height: calc(var(--vh, 76vh) * 100);
}
.adItem {
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: #f7f9fa;
    display: flex;
    justify-content: center;
}
