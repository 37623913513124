@import "@/src/styles/common.scss";
.pd-container {
    display: grid;
    grid-template-columns: 25% auto;
    grid-row-gap: 0.2rem;
    margin-bottom: 0.3rem;
    &:nth-child(1) {
        grid-column: 25%;
    }

    .wrapper-container {
        display: flex;
        .picker-wrapper {
            width: calc(100% / 3);
            margin: 0;
            .icon {
                right: 0.1rem;
            }
            .input-content-pd {
                &.pd-of-left {
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                    border-right-width: 0;
                }
                &.pd-of-right {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }

    .checked-container {
        margin: 0;
        grid-column: 2 / -1;
        .label-classnames.label {
            font-size: 0.24rem;
        }
    }
    .sub-text {
        font-size: 0.24rem;
        color: $text_3;
        grid-column: 2 / -1;
    }
    .pd-error {
        grid-column: 2 / -1;
    }
    .checkbox-class {
        border-color: $main !important;
    }
}
.pd-iframe-container {
    .pd-iframe {
        width: 100%;
    }
    .rx-form-hlep-p {
        font-size: 0.28rem;
        color: $text_2;
    }

    .rx-form-help-title {
        margin-bottom: 0.3rem;
        text-align: left;
        @include use-font-title($text_2);
    }
}
