@import "@/src/styles/common.scss";
.rxTitle {
    @include use-font-normal($text_2);
    white-space: break-spaces;
    // grid-row: 1 / span 2;
    .title-container {
        display: flex;
        align-items: center;
        .title {
            font-family: var(--font-montserrat-medium);
        }
        .faq-icon {
            color: $main;
        }
    }
    .detail {
        @include use-font-small();
        margin-top: 0.12rem;
        display: block;
    }
}
