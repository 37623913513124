@import "@/src/styles/common.scss";
.product {
    height: calc(var(--vh, 100vh) * 100 - 1rem);
    width: 100vw;
    display: flex;
    position: relative;
    .show {
        width: 100%;
        position: absolute;
        top: 0;
    }
    .info {
        position: absolute;
        bottom: 0.1rem;
        border-radius: 0.24rem;
        z-index: 45;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        width: calc(100% - 0.4rem);
        margin: 0 0.2rem;
        padding: 0.1rem 0.2rem 0.3rem;
        margin: 0px 0.2rem 0.3rem;
        .p_w {
            font-size: 0.36rem;
            font-family: var(--font-montserrat-medium);
        }
        .product-name {
            font-family: var(--font-montserrat-bold);
            font-size: 0.28rem;
        }
        .color-select {
            margin-top: 0.2rem;
        }
    }
}
