@import "@/src/styles/common.scss";
.od-os-container {
    .content-title-column {
        display: grid;
        grid-template-rows: repeat(3, auto); // 高度由内容撑开
        grid-template-columns: repeat(4, 4fr);
        grid-row-gap: 0.2rem;
        margin-bottom: 0.3rem;
        .odos-title {
            max-width: 100%;
            .od-os-content-title {
                justify-content: center;
            }
        }
        .input-root {
            color: $main;
            &.od_sph,
            &.os_sph {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            &.od_cyl,
            &.os_cyl {
                border-left-width: 0 !important;
                border-radius: 0 !important;
            }
            &.od_axis,
            &.os_axis {
                // color:
                border-left-width: 0 !important;
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
                &.disabled {
                    color: $gray_4;
                }
            }
        }
        .picker-wrapper {
            // width: 1.88rem;
            margin: 0;
            .icon {
                right: 0.1rem;
            }
            .select-input-icon-down {
                @include use-icon-small();
                position: absolute;
                right: 0.14rem;
            }
        }
        .axis {
            margin: 0;
        }
    }
}
// help.scss
.rx-form-hlep-p {
    font-size: 0.28rem;
    color: $text_2;
}

.rx-form-help-title {
    margin-bottom: 0.3rem;
    text-align: left;
    @include use-font-title($text_2);
}
