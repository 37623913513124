@import "@/src/styles/common.scss";
.product-bottom-container {
    position: fixed;
    z-index: 3;
    left: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 0.1rem 0.1rem rgba(13, 13, 13, 0.05);
    background-color: #fff;
    .flex-jusctify-product-try-on {
        width: calc(100% - 4rem);
        justify-content: space-between;
        background-color: #fff;
        .try-on-btn {
            border-radius: 0 !important;
            border-color: transparent !important;
        }
    }
}

.empty-heart-icon.button-line-icon {
    @include use-icon-normal();
    margin: 0 0.2rem;
}
