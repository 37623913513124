@import "@/src/styles/common.scss";
.tag {
    padding: 0.04rem 0.1rem;
    border-radius: 0.2rem;
    font-size: 0.217rem; // 这个数值有点儿无语
    transform: scale(0.8);
    line-height: 1.5;
    width: max-content;
    &.visibility-hidden {
        visibility: hidden !important;
        margin: 0;
        padding: 0;
    }
}
