@import "@/src/styles/common.scss";
.rx-select-modal {
    .select-eye-value-title {
        font-size: 0.32rem;
    }
    .select-eye-value-children {
        height: calc(100vh - 1.6rem);
        overflow: hidden;
        padding-bottom: 3.04rem;
        .rx-select-container {
            height: 100%;
            overflow: auto;
            width: 100%;
            height: 100%;
            background: #fff;
            display: flex;
            flex-direction: column;
            .select-item-container {
                display: flex;
                flex: 1;
                overflow-y: scroll;

                justify-content: space-between;
                font-weight: bolder;
                margin-top: 0;
                padding: 0;
                p {
                    @include use-font-title();
                    text-align: center;
                    margin-bottom: 0;
                }
                .select-item {
                    border-radius: 0.2rem 0.2rem 0px 0px;
                    width: 3.25rem;
                    padding: 0;
                    &:first-child {
                        height: 100%;
                        background: #d5edf9;
                    }

                    &:last-child {
                        background: #f9eaef;
                    }
                    .select-item-title {
                        padding: 0.4rem 0;
                    }
                    .select-item-ul {
                        display: flex;
                        flex-wrap: wrap;
                        .active {
                            background: $main;
                            color: #fff;
                        }
                        &.left-bg {
                            background: #d5edf9;
                        }
                        &.right-bg {
                            background: #f9eaef;
                        }

                        div {
                            width: 1.4rem;
                            text-align: center;
                            padding: 0.04rem 0;
                            border: 0.02rem solid #dadada;
                            background: #fff;
                            cursor: pointer;
                            margin: 0.1rem;
                            border-radius: 0.08rem;
                            @include use-font-normal();
                        }
                    }
                }
            }
        }
    }
    .footer-container {
        padding: 0.4rem;
        width: 100%;
        box-shadow: 0px -0.03rem 0.06rem rgba(0, 0, 0, 0.16);
        .cancel {
            margin-top: 0.2rem;
        }
    }
}
