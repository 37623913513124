@import "@/src/styles/common.scss";
// 规整样式列表

.product-icon-heart-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.18rem;
    &.product-show-tag-left {
        justify-content: space-between;
    }
    .product-icon-heart {
        width: 0.44rem;
        height: auto;
    }
}

.img-container {
    margin: 0 auto;
}
.img-content {
    height: auto;
    object-fit: contain;
}

.product_name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 10px 0;
    width: 100%;
    font-size: 0.28rem;
    color: #000;
    text-align: left;
    height: 0.7rem;
}

.product-row-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 0.65rem;
    &.product-info-line {
        width: 100%;
        .product-row {
            display: flex;
            align-items: center;
        }
        .product-name {
            font-size: 0.24rem;
            transform: scale(0.906);
            color: $text;
            font-family: var(--font-montserrat-bold);
            margin: 0;
        }
        .product-state-icon {
            width: 0.55rem;
            height: auto;
            margin-right: 0.1rem;
        }
    }
    .price-tryon-button-container {
        font-size: 0.24rem;

        .p_w {
            font-size: 0.24rem;
            color: $main;
            margin: 0;
            s {
                font-size: 0.24rem;
                transform: scale(0.906);
                color: #92969c;
            }
        }

        .try-on-button {
            margin-top: 0;
            &.not-single-button {
                margin-top: 0.18rem;
            }
        }
    }
    .show-single-style {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .p_w {
            order: 1;
        }
    }
}
.btemplate-try-on {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.double-col {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &.single-col {
        .products-reviews {
            order: 1;
        }
    }
    .products-reviews {
        font-size: 0.24rem;
        display: flex;
        // align-items: center;
    }
}
.color-select-container {
    margin-top: 0.1rem;
    text-align: left;
}
// }
