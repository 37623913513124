@import "@/src/styles/common.scss";
@mixin default_layout() {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@mixin search_type_item() {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-bottom: 0.2rem;
    margin-right: 0.2rem;
    transition: all 0.3s;
    transform: scale(1);
    &:hover {
        color: $main;
    }
}

.products_wrapper {
    padding: 0;
    // 搜索记录
    .space_between_layout {
        @include default_layout();
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.1rem;
        .search-type-title {
            @include use-font-title();
            margin: 0;
        }
        .gtm_search_recent_clean {
            cursor: pointer;
            padding: 0 0.1rem;
            .icon-delete-class {
                @include use-icon-normal();
            }
        }
    }
    .recent_search_layout {
        @include default_layout();
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 0.4rem;
        max-height: 1.1rem;
        overflow-y: hidden;
        .search-type-item {
            @include search_type_item();
        }
    }

    // 热搜关键词
    .search-type-title {
        @include use-font-title();
        margin-bottom: 0.4rem;
    }
    .popular_search_layout {
        @include default_layout();
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 0.4rem;
        .search-type-item {
            @include search_type_item();
            &.search-type-item-hot {
                color: #f06363;
                &:hover {
                    color: #f06363;
                    background: #fff;
                    border: 0.02rem solid #f06363;
                }
            }
        }
    }

    // 热销商品展示
    .hot-product-container {
        .show_on_time_product_layout {
            @include default_layout();
            justify-content: space-between;
            margin-bottom: 0.2rem;
            .search-type-title {
                @include use-font-title();
                margin-bottom: 0;
            }
            .product-more {
                color: $main;
                text-transform: capitalize;
            }
        }
        .search-type-title {
            @include use-font-title();
        }
        .product_data_wrapper {
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            justify-content: space-between;
            .hot-products-item {
                width: 45%;
                margin-bottom: 0.4rem;
                .img_container {
                    min-height: 1.4rem;
                    max-height: 1.6rem;
                    overflow-y: hidden;
                    .img-content {
                        width: 100%;
                    }
                }
                p {
                    text-align: center;
                    margin-bottom: 0;
                    margin-top: 10px;
                    font-size: 0.24rem;
                }
            }
        }
    }
}
.loading_layout {
    @include default_layout();
    margin-top: 20px;
    min-height: 40vh;
}
